
function getImage(imageUrl) { 
	if (imageUrl !== null && imageUrl !== undefined && imageUrl !== '') { 
				return imageUrl
		} else { 
				return "/static/movement_logo.jpg"
		}
}

function getProfileImage(imageUrl) { 
		const personUrl = "/static/person.png"
		if (imageUrl && imageUrl !== "") { 
			return imageUrl
		} else { 
			return personUrl
		}
}

function getUserImage(user) { 
	return user.image_url !== null ? getProfileImage(user.image_url) : getPossibleProfileImage(user.profile_image);
}

function getPossibleProfileImage(profileImage) {
	console.log(profileImage) 
	if (profileImage == null || profileImage == undefined) { 
		return "/static/person.png"
	} else { 
		return getProfileImage(profileImage.image)
	}
}



export { getPossibleProfileImage, getProfileImage, getImage, getUserImage }